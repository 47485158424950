<template>
  <popup :width="width" :heightPopup="height" :closePopupEvent="()=>closePopupCB('fbClose')">
    <div slot="header">
      <h6 class="login-header">Please enter your Email ID</h6>
    </div>
    <div class="vlt-container">
      <div class="change-details">
        <p>We are unable to get your Email, please enter your email to continue</p>
        <div class="vlt-input-group input-ico">
          <label>Email</label>
          <div class="vlt-input">
            <input
              type="text"
              aria-label="email"
              v-model="email"
              placeholder="Please enter your email Id"
              autocomplete="off"
              @focusin="actFocused"
            />
            <p class="error-message">{{ emailError }}</p>
          </div>
        </div>

        <app-loader v-show="showLoading"></app-loader>

        <div class="change-btn">
          <button type="button" class="btn-login" aria-label="Change" @click="actContinue">CONTINUE</button>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { EventBus } from "@/main";
import { mapActions, mapGetters, mapMutations } from "vuex";
// import SimpleCrypto from "simple-crypto-js";

export default {
  data() {
    return {
      width: "40%",
      height: "50%",
      emailError: "",
      showLoading: false,
      email: "",
      social: {}
    };
  },
  props: ["closePopupCB", "dataFacebook","page"],
  methods: {
    ...mapActions(["actProfileUpdate"]),
    actFocused() {
      this.emailError = "";
    },
    actContinue() {
      if (!this.email) {
        this.emailError = "Please enter your email Id";
        return;
      }
      this.social = {
        app: "FB",
        token: this.dataFacebook,
        email: this.email
      };
      // console.log("social ", this.social)
      this.showLoading = true;
      EventBus.$emit("loginUser", this.social, this.page);
    },
    closeCurrent(){
      this.closePopupCB();
    },
    promptAgain(){
      this.emailError="Not able to login. Please enter you email again.";
    }
  },
  created(){
    EventBus.$on("loginSuccess",this.closeCurrent);
    EventBus.$on("signupSuccess",this.closeCurrent);

    EventBus.$on("loginError",this.promptAgain);
    EventBus.$on("signupError",this.promptAgain);

  },
  beforeDestroy(){
    EventBus.$off("loginSucess",this.closeCurrent);
    EventBus.$off("loginError",this.promptAgain);

  },
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      )
  }
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.vlt-container {
  font-family: $font-family;
  font-weight: $font-weight-regular;

  .change-details {
    padding: 5% 20% 3%;
    .error-message {
      margin-top: 3%;
      color: $font-clr-red;
      text-align: left;
    }
    .vlt-input-group {
      margin-top: 5%;
      padding: 0 10%;
    }
    .change-input {
      border: 1px solid $clr-bg-gray-light-4;
      border-radius: 2px;
      width: 100%;
      height: 30px;
      padding: 3%;
      margin-top: 2%;
      color: $font-clr-white;
      caret-color: $font-clr-white;
    }
    .change-input-normal {
      background: $clr-bg-gray-light-3 0% 0% no-repeat padding-box;
    }
    .change-input-focused {
      background: $clr-bg-gray-dark-1 0% 0% no-repeat padding-box;
    }
    p {
      font-family: $font-family;
      font-weight: $font-weight-medium;
      font-size: 14px;
      text-align: center;
      color: $font-clr-gray;
      //   margin-top: 5%;
    }
    label {
      font-family: $font-family;
      font-weight: $font-weight-medium;
      margin-top: 0%;
      margin-bottom: 0%;
    }
    input {
      margin-top: 2%;
      padding: 2%;
    }
    .change-btn {
      margin: 10% 0% 0%;
      padding: 0 10%;
    }
    .ico-img {
      top: 15px;
    }
    .change-input-focused {
      background: $clr-bg-gray-dark-1 0% 0% no-repeat padding-box;
    }
  }
}
</style>